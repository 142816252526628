import { BranchActionList, BranchType } from "../actions/branchActions"
import { Action } from "../actions/types"

const initialValue: Array<BranchType> = []

const branchReducer = (state: Array<BranchType> = initialValue, action: Action) => {
    switch (action.type) {

        case BranchActionList.ADD_BRANCH:
            return [
                ...state,
                action.data,
            ]

        case BranchActionList.UPDATE_BRANCH:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case BranchActionList.FETCH_BRANCH:
            return action.data

        case BranchActionList.DELETE_BRANCH:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default branchReducer
