import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { fetchDropDown } from './redux/actions/dropDownAction';
import Course from "./pages/Course";
import Branch from "./pages/Branch";
import FeesMaster from "./pages/FeesMaster";
import QualificationMaster from "./pages/QualificationMaster";
import AddStudent from "./pages/AddStudent";
import MainStudentView from "./pages/MainStudentView";
import EditStudent from "./pages/EditStudent";
import StudentAttachment from "./pages/StudentAttachment";
import { AppDispatch } from "./redux/store";
import { DropDownMaster } from "./pages/DropDownMaster";
import NotFoundPage from "./pages/NotFoundPage";
import Login from "./pages/Login";
import { StoreState } from "./redux/reducers";
import { AuthUserType } from "./redux/actions/authUserActions";
import { fetchRole, fetchUser } from "./redux/actions/userActions";
import Logout from "./pages/Logout";
import User from "./pages/User";
import { fetchBranch } from "./redux/actions/branchActions";

function App() {

  const dispatch = useDispatch<AppDispatch>()
  const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)

  console.log(authUser);

  // const authendicated = true
    const authenticated = typeof authUser.token === 'string' && authUser.token !== undefined && authUser.token !== ''

  useEffect(() => {
    if (authenticated) {
      dispatch(fetchUser()) 
      dispatch(fetchRole())
      dispatch(fetchBranch())
      dispatch(fetchDropDown())
    }
  }, [authenticated])

  return (
    <BrowserRouter>
      <Routes>        
        <Route path="/" element={authenticated ? <Branch /> : <Navigate to='/login' />} />
        <Route path="/login" element={!authenticated ? <Login /> : <Navigate to='/' />} />
        <Route path="/logout" element={authenticated ? <Logout /> : <Navigate to='/' />} />
        <Route path="/course" element={authenticated ? <Course /> : <Navigate to='/' />} />
        <Route path="/fees-master" element={authenticated ? <FeesMaster /> : <Navigate to='/' />} />
        <Route path="/qualification-master" element={authenticated ?<QualificationMaster /> : <Navigate to='/' />} />
        <Route path="/student" element={authenticated ? <MainStudentView /> : <Navigate to='/' />} />
        <Route path="/student-info" element={authenticated ? <AddStudent /> : <Navigate to='/' />} />
        <Route path="/student-info/:id" element={authenticated ? <EditStudent /> : <Navigate to='/' />} />
        <Route path="/student-attachment/:id" element={authenticated ? <StudentAttachment /> : <Navigate to='/' />} />
        <Route path="/user" element={authenticated ? <User /> : <Navigate to='/' />} />
        <Route path="/dropdown-master" element={authenticated ? <DropDownMaster /> : <Navigate to='/' />} />
        <Route path="*" element={authenticated ? <NotFoundPage />: <Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
