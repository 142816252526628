import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../config';
import { StudentType } from "./Interface";
import Main from "../components/custom/Main";
import PageContainer from "../components/custom/PageContainer";
import CollapseCard from "../components/custom/CollapseCard";
import * as Feather from 'react-feather';
import { Popover, OverlayTrigger, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { StoreState } from "../redux/reducers";
import { AuthUserType } from "../redux/actions/authUserActions";
import { fetchUser, UserType } from "../redux/actions/userActions";

const MainStudentView = () => {
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)
    const [studentList, setStudentList] = useState<StudentType[]>([]);
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        dispatch(fetchUser())
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: StudentType[] }>(`${apiUrl}/student/studentByAuthUser/?branchId=${authUser.branchId}&userId=${authUser.userId}`)
            .then((response) => {
                console.log(response);
                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }
    

    const handleAddNewStudent = () => {
        navigate('/student-info');
    }

    const handleViewDetails = (id: number) => {
        navigate(`/student-info/${id}`);
    }

    const handleAttachment = (id: number) => {
        navigate(`/student-attachment/${id}`);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation()
    }

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/student/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }

    // Popovers for each button
    const viewPopover = (
        <Popover id="popover-view">
            <Popover.Body>View student details</Popover.Body>
        </Popover>
    );

    const attachmentPopover = (
        <Popover id="popover-attachment">
            <Popover.Body>Attach documents</Popover.Body>
        </Popover>
    );

    const deletePopover = (
        <Popover id="popover-delete">
            <Popover.Body>Delete student</Popover.Body>
        </Popover>
    );

    return (
        <Main>
            <PageContainer title="Student View">
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-success" onClick={handleAddNewStudent}>Add New Student</button>
                </div>
                <CollapseCard title="Student List">
                    <div className="container-fluid" style={{ width: "100%" }}>
                        <table className="table table-striped">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Register No</th>
                                    <th>Branch</th>
                                    <th>Course</th>
                                    <th>Batch</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentList.map((stu, index) => (
                                    <tr key={stu.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <img
                                                src={apiUrl + "/" + stu.photoUrl}
                                                alt={stu.name}
                                                className="student-thumbnail"
                                                style={{ objectFit: "contain", height: "70px", width: "70px" }}
                                            />
                                        </td>
                                        <td>{stu.name}</td>
                                        <td>{stu.registerNumber}</td>
                                        <td>{stu.branchName}</td>
                                        <td>{stu.courseName}</td>
                                        <td>{stu.batch}</td>
                                        <td>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={viewPopover}
                                            >
                                                <Button
                                                    className="btn btn-sm text-white"
                                                    style={{ backgroundColor: "#10296C", padding: "5px", borderRadius: "5px", marginRight: "5px" }}
                                                    onClick={() => handleViewDetails(stu.id)}
                                                >
                                                    <Feather.Eye style={{width: "20px"}} />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={attachmentPopover}
                                            >
                                                <Button
                                                    className="btn btn-sm text-white"
                                                    style={{ backgroundColor: "#10296C", padding: "5px", borderRadius: "5px", marginRight: "5px" }}
                                                    onClick={() => handleAttachment(stu.id)}
                                                >
                                                    <Feather.Paperclip style={{width: "20px"}}/> 
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={deletePopover}
                                            >
                                                <Button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => deleteHandler(stu.id)}
                                                >
                                                    <Feather.Trash2 style={{width: "20px"}}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer />
            </PageContainer>
        </Main>
    );
}

export default MainStudentView;
