import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import Card from '../components/custom/Card';
import "../styles/Table.css";
import StudentInformation from './StudentInformation';
import { useParams } from "react-router-dom"

const EditStudent = () => {
    const { id } = useParams()
  return (
    <Main>
      <PageContainer title="Edit Student">
        <Card title= "Student Information">
            <StudentInformation 
             id = {id}
             type = "edit"
            />
        </Card>
      </PageContainer>
  
    </Main>
  );
};

export default EditStudent;
