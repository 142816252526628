import React, { FC, useState } from "react";
import * as Feather from 'react-feather';
import { Link } from 'react-router-dom';

export interface MenuItemTypes {
    key: string;
    label: string;
    isTitle?: boolean;
    Icon?: Feather.Icon;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemTypes[];
}

const MENU_ITEMS: MenuItemTypes[] = [
    {
        key: 'branch',
        label: 'Branch',
        isTitle: false,
        Icon: Feather.BarChart,
        url: '/',
    },
    {
        key: 'course',
        label: 'Course',
        isTitle: false,
        Icon: Feather.BookOpen,
        url: '/course',
    },
    {
        key: 'feesmaster',
        label: 'Fees Master',
        isTitle: false,
        Icon: Feather.DollarSign,
        url: '/fees-master',
    },
    // {
    //     key: 'qualificationmaster',
    //     label: 'Qualification Master',
    //     isTitle: false,
    //     Icon: Feather.Award,
    //     url: '/qualification-master',
    // },
    {
        key: 'student',
        label: 'Student',
        isTitle: false,
        Icon: Feather.Users,
        url: '/student',
    },
    {
        key: 'user',
        label: 'User',
        isTitle: false,
        Icon: Feather.User,
        url: '/user',
    },
    {
        key: 'dropdownMaster',
        label: 'DropDown Master',
        isTitle: false,
        Icon: Feather.ChevronDown,
        url: '/dropdown-master',
    },

    // {
    //     key: 'report',
    //     label: 'Report',
    //     isTitle: false,
    //     Icon: Feather.Printer,
    //     children: [
    //         {
    //             key: 'coursereport',
    //             label: 'Details of Course',
    //             url: '/coursereport',
    //             Icon: Feather.Repeat,
    //             parentKey: 'report',
    //         }

    //     ]
    // }
];


const SideNavBar: FC = () => {

    return (
        <aside className="main-sidebar elevation-4" style={{backgroundColor: "#10296C", position: "fixed"}}>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="https://i.pinimg.com/550x/90/c8/d0/90c8d0fde36b92c18a6c19a06b4b2735.jpg" className="img-circle elevation-2" alt="User" />
                    </div>
                    <div className="info">
                        <Link to="#" className="d-block">College Management</Link>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" data-accordion="false">
                        {MENU_ITEMS?.map((menuItem, index) => {
                            const { Icon } = menuItem
                            return (
                                <React.Fragment key={menuItem.key}>
                                    {menuItem.children ?
                                        <MenuItem
                                            menuItem={menuItem}
                                        />

                                        :
                                        <li className="nav-item">
                                            <Link to={menuItem.url || ''} className="nav-link">
                                                {Icon && <Icon style={{ marginRight: "5px" }} />}
                                                <p>
                                                    {menuItem.label}
                                                </p>
                                            </Link>
                                        </li>}
                                </React.Fragment>
                            )
                        })
                        }
                        <li className="nav-item">
                            <Link to={"/logout"} className="nav-link">
                                <Feather.LogOut style={{ marginRight: "5px" }} />
                                <p>
                                    Logout
                                </p>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside >
    )
}

const MenuItem = (props: { menuItem: MenuItemTypes }) => {
    const { Icon } = props.menuItem
    const [showSubMenu, setShowSubMenu] = useState<boolean>(false)

    const handleToggleMenuClick = () => {
        setShowSubMenu(!showSubMenu)
    }

    return <>
        <li className="nav-item">
            <Link to={"#"} className="nav-link" onClick={handleToggleMenuClick}>
                {Icon && <Icon style={{ marginRight: "5px" }} />}
                <p>{props.menuItem.label}<i className={!showSubMenu ? "right fas fa-angle-left" : 'right fas fa-angle-down'}></i></p>
            </Link>
            <ul className="nav nav-treeview" style={{ display: showSubMenu ? 'block' : 'none' }}>
                {
                    props.menuItem.children?.map((childItem, index2) => {
                        return (
                            <li className="nav-item" key={index2}>
                                <Link to={childItem.url || '#'} className="nav-link">
                                    <i className="fas fa-angle-right nav-icon"></i><p>{childItem.label}</p>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    </>
}
export default SideNavBar;