import React from "react";
import ControlSideNavBar from './ControlSideNavBar';
import Footer from './Footer';
import SideNavBar from './SideNavBar';
import TopNavBar from './TopNavBar';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <>
      <SideNavBar />
      <TopNavBar />
          {children} 
      <Footer />
    </>
  );
}

export default Main;
