import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main'
import PageContainer from '../components/custom/PageContainer'
import Card from '../components/custom/Card'
import { apiUrl } from '../config';
import { QualificationType } from './Interface'; 
import CollapseCard from '../components/custom/CollapseCard';
import "../styles/Table.css"

const QualificationMaster = () => {
    const [qualificationList, setQualificationList] = useState<QualificationType[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [id, setId] = useState<number>(0);
    const [qualification, setQualification] = useState<string>("");
    const [noOfYears, setNoOfYears] = useState<string>("");

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [qualificationError, setQualificationError] = useState<string>("");
    const [noOfYearsError, setNoOfYearsError] = useState<string>("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: QualificationType[] }>(`${apiUrl}/qualificationMaster/`)
            .then((response) => {
                setQualificationList(response.data.data);
            })
            .catch((error) => console.log(error));
    };

    const clearHandler = () => {
        setQualification("");
        setNoOfYears("");

        setQualificationError("");
        setNoOfYearsError("");

        setEditFlag(false);
    };

    const addHandler = () => {
        let error = false;

        if (qualification === "") {
            setQualificationError('Qualification is required');
            error = true;
        } else if (qualification.length > 50) {
            setQualificationError("Qualification must be within 50 characters");
            error = true;
        }

        if (noOfYears === "") {
            setNoOfYearsError('Number of years is required');
            error = true;
        } else if (isNaN(Number(noOfYears))) {
            setNoOfYearsError('Number of years must be a valid number');
            error = true;
        }

        const data = { qualification, noOfYears };

        if (!error) {
            axios.post(`${apiUrl}/qualificationMaster/`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    };

    const editHandler = (id: number, qualification: string, noOfYears: string) => {
        setEditFlag(true);
        setId(id);
        setQualification(qualification);
        setNoOfYears(noOfYears);
    };

    const updateHandler = () => {
        let error = false;

        if (qualification === "") {
            setQualificationError('Qualification is required');
            error = true;
        } else if (qualification.length > 50) {
            setQualificationError("Qualification must be within 50 characters");
            error = true;
        }

        if (noOfYears === "") {
            setNoOfYearsError('Number of years is required');
            error = true;
        } else if (isNaN(Number(noOfYears))) {
            setNoOfYearsError('Number of years must be a valid number');
            error = true;
        }

        const data = { id, qualification, noOfYears };

        if (!error) {
            axios.put(`${apiUrl}/qualificationMaster/?id=${id}`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setEditFlag(false);
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/qualificationMaster/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    return (
        <Main>
            <PageContainer title="Qualification Master">
                <Card title={editFlag ? "Edit Qualification" : "Add Qualification"}>
                    <div className="row">
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Qualification <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter qualification..." type="text" onChange={(e) => {
                                setQualification(e.target.value);
                            }} value={qualification} />
                            {qualificationError && <div className="text-danger">{qualificationError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">No. of Years <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter no. of years..." type="text" onChange={(e) => {
                                setNoOfYears(e.target.value);
                            }} value={noOfYears} />
                            {noOfYearsError && <div className="text-danger">{noOfYearsError}</div>}
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm" onClick={updateHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Update</button> :
                            <button className="btn btn-sm" onClick={addHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Add</button>}
                    </div>
                </Card>
                <CollapseCard title="Qualification List">
                    <div className="container-fluid" style={{ width: "100%"}}>
                        <table className="table table-striped">
                            <thead style={{backgroundColor: "#10296C", color: "#fff"}}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Qualification</th>
                                    <th>No. of Years</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                            {qualificationList.map((qualification, index) => (
                                <tr key={qualification.id}>
                                    <td>{index + 1}</td>
                                    <td>{qualification.qualification}</td>
                                    <td>{qualification.noOfYears}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px', backgroundColor: '#10296C' }} onClick={() => editHandler(qualification.id, qualification.qualification, qualification.noOfYears)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(qualification.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                </CollapseCard>
            </PageContainer>
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this qualification?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Main>
    )
}

export default QualificationMaster;
