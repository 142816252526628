import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import { apiUrl } from '../config';
import { StudentAttachmentType } from './Interface';
import "../styles/Table.css";
import { useParams } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import icons

const StudentAttachment = () => {
  const { id } = useParams();
  const [attachments, setAttachments] = useState<StudentAttachmentType[]>([]);
  const [attachmentFields, setAttachmentFields] = useState<{ attachmentName: string; file: File | null }[]>([
    { attachmentName: '', file: null }
  ]);
  const [registerNumber, setRegisterNumber] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [branchName, setBranchName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [name, setName] = useState('');
  const [batch, setBatch] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  useEffect(() => {
    if (!id) return;

    axios.get(`${apiUrl}/student/studentRegisterNo/?id=${id}`)
      .then(response => {
        const data = response.data.data;
        if (data) {
          setPhotoUrl(data.photoUrl);
          setRegisterNumber(data.registerNumber);
          setName(data.name);
          setBranchName(data.branchName);
          setCourseName(data.courseName);
          setBatch(data.batch);
        }
      })
      .catch(error => console.log(error));
  }, [id]);

  useEffect(() => {
    if (!registerNumber) return;
    fetchAttachments();
  }, [registerNumber]);

  const fetchAttachments = () => {
    axios.get<{ data: StudentAttachmentType[] }>(`${apiUrl}/studentAttachments/?registerNumber=${registerNumber}`)
      .then(response => {
        setAttachments(response.data.data);
      })
      .catch(error => console.log(error));
  };

  const handleAttachmentNameChange = (index: number, value: string) => {
    setAttachmentFields(prev => {
      const updatedFields = [...prev];
      updatedFields[index] = { ...updatedFields[index], attachmentName: value };
      return updatedFields;
    });
  };

  const handleFileChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setAttachmentFields(prev => {
        const updatedFields = [...prev];
        updatedFields[index] = { ...updatedFields[index], file: files[0] };
        return updatedFields;
      });
    }
  };
  

  const addAttachmentField = () => {
    setAttachmentFields(prev => [
      ...prev,
      { attachmentName: '', file: null }
    ]);
  };

  const removeAttachmentField = (index: number) => {
    setAttachmentFields(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpload = () => {
    const emptyField = attachmentFields.some(field => !field.file || !field.attachmentName || !registerNumber);
    if (emptyField) {
      toast.error('Please fill in all fields and select a file.', { position: 'top-right' });
      return;
    }

    attachmentFields.forEach(field => {
      if (field.file) {
        const formData = new FormData();
        formData.append('file', field.file);
        formData.append('attachmentName', field.attachmentName);
        formData.append('registerNumber', registerNumber);

        axios.post(`${apiUrl}/studentAttachments/`, formData)
          .then(response => {
            toast.success('File uploaded successfully!', { position: 'top-right' });
            fetchAttachments();
          })
          .catch(error => {
            toast.error('Failed to upload file.', { position: 'top-right' });
          });
      }
    });

    setAttachmentFields([{ attachmentName: '', file: null }]); // Reset fields
  };

  const handleView = (url: string) => {
    if (url) {
      window.open(`${apiUrl}/studentAttachments/?file=${url}`, '_blank');
    } else {
      toast.error('Invalid URL for the attachment.', { position: 'top-right' });
    }
  };

  const openDeleteConfirmation = (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const confirmDeleteHandler = () => {
    axios.delete(`${apiUrl}/studentAttachments/?id=${selectedId}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('File deleted successfully!', { position: 'top-right' });
          fetchAttachments();
          closeDeleteConfirmation();
        } else {
          toast.error('Failed to delete file.', { position: 'top-right' });
        }
      })
      .catch(error => {
        console.error('Error deleting file:', error);
        toast.error('Failed to delete file.', { position: 'top-right' });
      });
  };

  return (
    <Main>
      <PageContainer title="Student Attachments">
        <Card title="Upload Attachment">
          <div className="row" style={{ width: "30%" }}>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              <img
                src={apiUrl + "/" + photoUrl}
                alt={name}
                className="student-thumbnail"
                style={{ objectFit: "contain", height: "100px", width: "100px" }}
              />
            </div>
            <div className='col-sm-12 col-md-12 col-lg-5' style={{ marginLeft: "0px" }}>
              <h6>Name: <span style={{ fontWeight: "normal" }}>{name}</span></h6>
              <h6>Register No: <span style={{ fontWeight: "normal" }}>{registerNumber}</span></h6>
              <h6>Branch: <span style={{ fontWeight: "normal" }}>{branchName}</span></h6>
              <h6>Course: <span style={{ fontWeight: "normal" }}>{courseName}</span></h6>
              <h6>Batch: <span style={{ fontWeight: "normal" }}>{batch}</span></h6>
            </div>
          </div>
          <div className="col mt-3 mb-3 me-4 d-flex justify-content-end">
            <Button variant="success" onClick={addAttachmentField} className="mt-3">
              <FaPlus /> Add Another Attachment
            </Button>
          </div>
          <>
          {attachmentFields.map((field, index) => (
            <div className="row" key={index}>
              <div className='col-sm-6 col-md-4 col-lg-3'>
                <label className="mt-3 mb-3">Attachment Name <span className='text-danger'>*</span>:</label>
                <input
                  className="form-control"
                  type="text"
                  value={field.attachmentName}
                  onChange={(e) => handleAttachmentNameChange(index, e.target.value)}
                />
              </div>
              <div className='col-sm-6 col-md-4 col-lg-3 custom-file'>
                <label className="mt-3 mb-3">PDF File <span className='text-danger'>*</span>:</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(index, e)}
                    />
                    <label className="custom-file-label">
                      {field.file ? field.file.name : 'Choose file...'}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-1 mt-3">
                {index > 0 && (
                  <Button variant="danger" onClick={() => removeAttachmentField(index)} className="" style={{ marginTop: "45%" }}>
                    <FaMinus />
                  </Button>
                )}
              </div>
            </div>
          ))}
          </>
          <div className="m-4 d-flex justify-content-end">
            <Button onClick={handleUpload} style={{ backgroundColor: "#10296C", color: "#fff" }}>Upload</Button>
            <Button className="btn btn-secondary ml-2" onClick={() => setAttachmentFields([{ attachmentName: '', file: null }])}>Clear</Button>
          </div>
        </Card>
        <CollapseCard title="Attachments List">
          <div className="container-fluid" style={{ width: "100%" }}>
            <table className="table table-striped">
              <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                <tr>
                  <th>S.No</th>
                  <th>Attachment Name</th>
                  <th>File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((attachment, index) => (
                  <tr key={attachment.id}>
                    <td>{index + 1}</td>
                    <td>{attachment.attachmentName}</td>
                    <td>
                      <button className="btn btn-sm" onClick={() => handleView(attachment.attachmentUrl || "")} style={{ backgroundColor: "#10296C", color: "#fff" }}>View</button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => openDeleteConfirmation(attachment.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CollapseCard>
        <ToastContainer />
        <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this attachment?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteConfirmation}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDeleteHandler}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </PageContainer>
    </Main>
  );
};

export default StudentAttachment;
