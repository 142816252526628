import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface AccountLayoutProps {
    helpText?: string;
    bottomLinks?: any;
    isCombineForm?: boolean;
    children?: any;
}

const AuthLayout = ({ helpText, bottomLinks, children, isCombineForm }: AccountLayoutProps) => {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
        };
    }, []);

    return (
        <>
            <div className="account-pages p-5" style={{backgroundColor: "#10296C"}}>
                <Container className='p-5'>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={isCombineForm ? 9 : 5}>
                            <Card className="bg-pattern">
                                <Card.Body className="p-4">
                                    <div className="text-center w-100 m-auto">
                                        <div className="auth-logo">
                                            {/* <Link to="/" className="logo logo-dark text-center">
                                                <span className="logo-lg">
                                                    <img src={LogoDark} alt="" height="50" />
                                                </span>
                                            </Link> */}

                                            <Link to="/" className="logo logo-light text-center">
                                                <span className="logo-lg">
                                                    <img src={process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "img/college_logo.png" : "../img/college_logo.png"} alt="" width="70%" />
                                                </span>
                                            </Link>
                                        </div>
                                        <h5 className="text-dark mb-4 mt-3 "><i className="fas fa-key mr-2"></i>{helpText}</h5>
                                        <hr/>
                                    </div>
                                    {children}
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                   <br/>
                   <br/>
                </Container>
                
            </div>
            <footer className="main-footer" style={{marginLeft:"0px"}}>
        <div className="float-right d-none d-sm-block">
            Copyright &copy; {new Date().getFullYear()}. All rights reserved.
        </div>
        Powered By <a href="http://www.mindzcube.com"><img src={process.env.PUBLIC_URL + "img/mindzcube.png"} height="30px" alt=""/></a>
    </footer>
            {/* <footer className="footer footer-alt">
                2015 - {new Date().getFullYear()} &copy; PITS{' '}
                <Link to="#" className="text-white-50">
                    Coderthemes
                </Link>
            </footer> */}
             
        </>
    );
};

export default AuthLayout;
