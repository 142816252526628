import 'react-toastify/dist/ReactToastify.css';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import Card from '../components/custom/Card';
import "../styles/Table.css";
import StudentInformation from './StudentInformation';

const AddStudent = () => {
  return (
    <Main>
      <PageContainer title="Add Student">
        <Card title= "Student Information">
            <StudentInformation id={""} type="add"/>
        </Card>
      </PageContainer>
  
    </Main>
  );
};

export default AddStudent;
