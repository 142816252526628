import React, { FormEventHandler } from 'react';

interface VerticalFromProps {
    defaultValues?: any;
    children?: any;
    formClass?: string;
    onSubmit?: FormEventHandler<HTMLFormElement>
}

const VerticalForm = ({
    defaultValues,
    children,
    onSubmit,
    formClass,
}: VerticalFromProps) => {

    return (
        <form onSubmit={onSubmit} className={formClass} noValidate>
            {Array.isArray(children)
                ? children.map((child) => {
                    return child.props && child.props.name
                        ? React.createElement(child.type, {
                            ...{
                                ...child.props,
                                key: child.props.name,
                            },
                        })
                        : child;
                })
                : children}
        </form>
    );
};

export default VerticalForm;
